@charset "UTF-8";

/* ---------------------------
  gird system container
--------------------------- */
/* -- config -------------- */
$container-unit: (
  container-unit-full: 0 #{$spc-XXS},
  container-unit-half: 0 #{$spc-XXXS}
) !default;
$container-unit-flg-full: container-unit-full;
$container-unit-flg-half: container-unit-half;
@function cont-unit($container-unit-key) {
  @return map-get($container-unit,$container-unit-key);
}

/* -- contaier ------------ */
.l-container {
  // width: 100%;
  width: 1200px;
  margin: 0 auto;
  padding: 0 2.0rem;
}
@include screen-mq(xl) {
  .l-container {
    max-width: #{$screen-xl}px;
    padding: 0 2.0rem;
  }
}
@include screen-mq(lg) {
  .l-container {
    max-width: #{$screen-lg}px;
    padding: 0 2.0rem;
  }
}
@include screen-mq(md) {
  .l-container {
    max-width: 100%;
    padding: 0 2.0rem;
  }
}
@include screen-mq(sm) {
  .l-container {
    max-width: 100%;
    padding: 0 2.0rem;
  }
}
@include screen-mq(xs) {
  .l-container {
    max-width: 100%;
    padding: 0 2.0rem;
  }
}

/* ---------------------------
  grid system inline
--------------------------- */
/* -- config -------------- */
$flex-layout-h: (
  left:    flex-start,
  center:  center,
  right:   flex-end,
  around:  space-around,
  justify: space-between
) !default;
$flex-layout-s-v: (
  top:    flex-start,
  middle: center,
  bottom: flex-end,
  base:   baseline,
  full:   stretch
) !default;
$flex-layout-m-v: (
  top:     flex-start,
  middle:  center,
  bottom:  flex-end,
  around:  space-around,
  justify: space-between,
  full:    stretch
) !default;
@function flex-h($flex-layout-h-key) {
  @return map-get($flex-layout-h,$flex-layout-h-key);
}
@function flex-s-v($flex-layout-s-v-key) {
  @return map-get($flex-layout-s-v,$flex-layout-s-v-key);
}
@function flex-m-v($flex-layout-m-v-key) {
  @return map-get($flex-layout-m-v,$flex-layout-m-v-key);
}

/* -- flexbox (inline) ---- */
.l-flex-single {
  flex-wrap: nowrap;
  justify-content: flex-h(left);
  align-items: flex-s-v(middle);
}
.l-flex-multi {
  flex-wrap: wrap;
  justify-content: flex-h(left);
  align-content: flex-m-v(middle);
}
.l-flex-single,
.l-flex-multi {
  display: flex;
  overflow: hidden;
    .l-flex-item.__col-1  { flex-basis: 8.3333%; max-width: 8.3333%; width: 8.3333%; }
    .l-flex-item.__col-2  { flex-basis: 16.6666%; max-width: 16.6666%; width: 16.6666%; }
    .l-flex-item.__col-3  { flex-basis: 25%; max-width: 25%; width: 25%; }
    .l-flex-item.__col-4  { flex-basis: 33.3333%; max-width: 33.3333%; width: 33.3333%; }
    .l-flex-item.__col-5  { flex-basis: 41.6666%; max-width: 41.6666%; width: 41.6666%; }
    .l-flex-item.__col-6  { flex-basis: 50%; max-width: 50%; width: 50%; }
    .l-flex-item.__col-7  { flex-basis: 58.3333%; max-width: 58.3333%; width: 58.3333%; }
    .l-flex-item.__col-8  { flex-basis: 66.6666%; max-width: 66.6666%; width: 66.6666%; }
    .l-flex-item.__col-9  { flex-basis: 75%; max-width: 75%; width: 75%; }
    .l-flex-item.__col-10 { flex-basis: 83.3333%; max-width: 83.3333%; width: 83.3333%; }
    .l-flex-item.__col-11 { flex-basis: 91.6666%; max-width: 91.6666%; width: 91.6666%; }
    .l-flex-item.__col-12 { flex-basis: 100%; max-width: 100%; width: 100%; }
    .l-flex-item.__quat   { flex-basis: 25%; max-width: 25%; width: 25%; }
    .l-flex-item.__half   { flex-basis: 50%; max-width: 50%; width: 50%; }
    .l-flex-item.__3-quat { flex-basis: 75%; max-width: 75%; width: 75%; }
    .l-flex-item.__full   { flex-basis: 100%; max-width: 100%; width: 100%; }
    @include screen-mq(xl) {
      .l-flex-item {
        padding: cont-unit($container-unit-flg-full);
      }
    }
    @include screen-mq(lg) {
      .l-flx-item {
        padding: cont-unit($container-unit-flg-full);
      }
    }
    @include screen-mq(md) {
      .l-flx-item {
        padding: cont-unit($container-unit-flg-full);
      }
    }
    @include screen-mq(sm) {
      .l-flx-item {
        padding: cont-unit($container-unit-flg-half);
      }
    }
    @include screen-mq(xs) {
      .l-flx-item {
        padding: cont-unit($container-unit-flg-half);
      }
    }
}

/* ---------------------------
  grid system block
--------------------------- */
/* -- config -------------- */
$sidebar-width: 300px;
$leftnav-width: 160px;
$gutter-col-width: $spc-S;
$grid-layout-col: (
  single:    100%,
  double:    repeat(2, 50%),
  triple:    repeat(3, 33.3333%),
  quad:      repeat(4, 25%),
  sidebar-l: #{$sidebar-width} 1fr,
  sidebar-r: 1fr #{$sidebar-width},
  leftnav:   #{$leftnav-width} 1fr #{$sidebar-width}
) !default;
@function grid-col($grid-layout-col-key) {
  @return map-get($grid-layout-col,$grid-layout-col-key);
}

/* -- gird (block) -------- */
.l-grid {
  display: grid;
    .l-grid-item.__col-single    { grid-template-columns: grid-col(single); }
    .l-grid-item.__col-double    { grid-template-columns: grid-col(double); grid-column-gap: $gutter-col-width; }
    .l-grid-item.__col-triple    { grid-template-columns: grid-col(triple); grid-column-gap: $gutter-col-width; }
    .l-grid-item.__col-quad      { grid-template-columns: grid-col(quad); grid-column-gap: $gutter-col-width; }
    .l-grid-item.__left-sidebar  { grid-template-columns: grid-col(sidebar-l); grid-column-gap: $gutter-col-width; }
    .l-grid-item.__right-sidebar { grid-template-columns: grid-col(sidebar-r); grid-column-gap: $gutter-col-width; }
    .l-grid-item.__leftnav       { grid-template-columns: grid-col(leftnav); grid-column-gap: $gutter-col-width; }
}