@charset "UTF-8";

/* ---------------------------
	body
--------------------------- */
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}
body {
  width: 100%;
  height: 100%;
  font-family: $dflt-font-family;
  font-size: 1.6rem;
  line-height: $dflt-line-height;
  color: $dflt-font-color;
  letter-spacing: $dflt-letter-spacing;
}
.l-wrap {
  width: 100%;
  height: auto !important;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
@include screen-mq(sm) {
  body {
    font-size: responsive-fs(16,$screen-sm);
  }
}
@include screen-mq(xs) {
  body {
    font-size: 1.4rem;
    line-height: 1.75;
  }
}

/* ---------------------------
  header
--------------------------- */
.l-header {
  width: 100%;
  padding: $spc-S 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  transition: $dflt-transition;
    .__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
        .__inner {
            &.__inner--left {
              flex-basis: 170px;
              max-width: 170px;
                .__logo {
                  width: 170px;
                  max-height: 30px;
                  vertical-align: bottom
                }
            }
            @include screen-mq(md) {
              &.__inner--center {
                display: none;
              }
              &.__inner--right {
                  .l-sns-links {
                    display: none;
                  }
              }
            }
        }
    }
}

/* ---------------------------
  footer
--------------------------- */
.l-footer {
  background: #313131;
  padding: $spc-R 0 $spc-S 0;
    .__wrap {
      display: flex;
      justify-content: space-between;
        .__inner {
            &.__inner--left {
                .__logo {
                  vertical-align: bottom;
                    &.__logo--first {
                      margin: 0 0 1rem 0;
                    }
                    &.__logo--second {
                      width: 170px;
                      height: auto;
                      margin: 0 0 0 0;
                    }
                }
				.__ad {
					  margin: 0 0 7rem 0;
				}	
            }
    	}
    @include screen-mq(md) {
        flex-wrap: wrap-reverse;
          .__inner {
            flex-basis: 100%;
            max-width: 100%;
              &.__inner--left {
                margin: 0 0 0 0;
              }
              &.__inner--right {
                margin: 0 0 $spc-S 0;
              }
          }
   		 }
	  @include screen-mq(xs) {
		 .__inner { 
              &.__inner--right iframe{
                width: 330px;
              }
			}
		}
    .o-copyright {
      font-size: $font-XS;
      color:#cccccc;
      text-align: center;
    }
}
}
	
/* ---------------------------
  main
--------------------------- */
.l-main {
}

/* ---------------------------
  aside
--------------------------- */
.l-aside {
}

