@charset "UTF-8";

.o-form-register {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
      border-radius: $dflt-radius;
      border: 1px solid $color-black;
      width: 100%;
      padding: $spc-XS;
        &.o-input-short {
          max-width: 150px;
        }
    }
    textarea {
      min-height: 200px;
    }
    label {
      margin: 0 $spc-R 0 0;
    }
    input[type="checkbox"]{
      vertical-align: baseline;
      position: relative;
      margin: 0 5px 0 0;
    }
	input[type="radio"] {
    position: relative;
	margin: 0 5px 0 0;	
    -webkit-appearance: button;
    appearance: button;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    content: "";
    background-color: #fff;
    border: 1px solid #999999;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: inset 4px 4px 10px rgba(0,0,0,0.1);
    box-shadow: inset 4px 4px 10px rgba(0,0,0,0.1);
    vertical-align: middle;
    cursor: pointer;
	}
	input[type="radio"]:checked:after {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    content: "";
    width: 8px;
    height: 8px;
    background: #2e2425;
    -webkit-border-radius: 8px;
    border-radius: 8px;
	}	
	input[type="radio"]:checked{
  background-image: radial-gradient(circle at 50% 50%, black 40%, transparent 30%);
}	
}