@charset "UTF-8";

/* ---------------------------
  html elements
--------------------------- */
p:not(:last-child) {
  margin: 0 0 $spc-S 0;
}
a:link,
a:visited,
a:hover,
a:active {
  color: $dflt-font-color;
}
