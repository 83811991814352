@charset "UTF-8";

/* ---------------------------
  common
--------------------------- */
::selection
{
    background: #000000;
    color: #ffffff;
}


/*-----------------------------------------------------------------------------------*/
/*.header-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
header {
  padding: 40px;
  position: fixed;
  z-index: 9999;
  .__logo{
  margin-left: 10%;
    @include screen-mq(md) {   
    margin-left: 4%;
    width: 40%;
    }
}
  #nav-content {
    padding: 20px;
    h2 {
      margin-bottom: 20px;
      color: #fff;
      font-size: 20px;
      border-bottom: 2px solid;
    }
    ul {
        li {
        margin-bottom: 20px;
          a {
            color: #fff;
          }
      }
    }
  }
  #nav-drawer {
      position: absolute;
      top: 0;
      right: 0;
      .header_logo {
        vertical-align: middle;
        margin-left: 30px;
      }
    }
/*チェックボックス等は非表示に*/
.nav-unshown {
  display: none;
}
}

/*アイコンのスペース*/
#nav-open {
    display: inline-block;
    width: 80px;
    height: 80px;
    vertical-align: middle;
    background: -webkit-gradient(linear,right top,left top,from(#2da9de),to(#1173b7));
    background: linear-gradient(-90deg,#2da9de,#1173b7);
    padding: 27px 20px;/*ハンバーガーアイコンをCSSだけで表現*/
    @include screen-mq(md) {
    width: 48px;
    height: 48px;
    padding: 16px 15px;
    }
span,span:before,span:after {
  position: absolute;
  height: 1px;
  /*線の太さ*/
  width: 40px;
  /*長さ*/
  border-radius: 3px;
  background: #fff;
  display: block;
  content: '';
  cursor: pointer;
    @include screen-mq(md) {
  width: 20px;
    }
}
span:before {
  bottom: -12px;
    @include screen-mq(md) {
  bottom: -8px;
    }
}
span:after {
  bottom: -24px;
    @include screen-mq(md) {
  bottom: -16px;
    }
}
}
/*閉じる用の薄黒カバー*/
#nav-close {
  display: none;
  /*はじめは隠しておく*/
  position: fixed;
  z-index: 99;
  top: 0;
  /*全体に広がるように*/
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

/*中身*/
#nav-content {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  /*最前面に*/
  width: 90%;
  /*右側に隙間を作る*/
  max-width: 330px;
  /*最大幅*/
  height: 100%;
  background: #fff;
  /*背景色*/
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  /*滑らかに表示*/
  -webkit-transform: translateX(-105%);
  transform: translateX(-105%);
  /*左に隠しておく*/
  background: $color-base-4;
}

/*チェックが入ったらもろもろ表示*/
#nav-input:checked ~ #nav-close {
  display: block;
  /*カバーを表示*/
  opacity: .5;
}

#nav-input:checked ~ #nav-content {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  /*中身を表示*/
  -webkit-box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
  box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
}

#nav-input:checked ~ #nav-close {
  display: block;
  /*カバーを表示*/
  opacity: .5;
}

#nav-input:checked ~ #nav-content {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  /*中身を表示*/
  -webkit-box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
  box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
}



/* ---------------------------
  01_index
--------------------------- */
.o-page-home {
  background: #eee;
  h1.__head{
    font-size: 3.4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    span.__subhead{
      font-size: $dflt-font-size;
      margin-left: 16px;
    }
    @include screen-mq(md) {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    span.__subhead{
      font-size: $spc-XS;
      margin-left: 12px;
    }

    }
  }
.o-mainvisual {
    position: relative;
          /* Opening
-----------------------------------------*/
.Opening {
position:relative;
background-color: #FFF;
}
.Opening ul li{
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.Opening ul li img.Opening__text{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform:translate(-50%, -50%);
  z-index: 9;
}
.Opening ul li span {
}
.Opening ul li span img.Opening__img{
  max-width: inherit;
  width: 100%;
  height: auto;
}
    h1.__head{
    position: absolute;
    z-index: 999;
    color: #fff;
    line-height: 1.4em;
    font-size: 72px;
    bottom: 50%;
    left: 10%;
    margin: auto;
    @include screen-mq(md) {
        font-size: 30px;
    }
  }
  
}
    @include screen-mq(md) {
      .o-mainvisual {
        background: url(../img/site/100_index/mainbg_768px.png) no-repeat center center/cover;
      }
    }
    @include screen-mq(xs) {
      .o-mainvisual {
        background: url(../img/site/100_index/mainbg_480px.png) no-repeat center center/cover;
      }
    }
    .o-section-01 {
      position: relative;
      .o-sect{
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: #fff;
        @include screen-mq(md) {
        width: 90%;
        }
      }
        .__wrap {
            margin: 64px;
            &.__wrap--first {
                .__inner {
                  .o-container{
                    .__box{
                      display: flex;
                      .__left{
                        width: 20%;
                        margin-right: 2%;
                      }
                      .__right{
                        width: 78%;
                      }
                    }
                  }
                }
        @include screen-mq(md) {
                .__inner {
                  .o-container{
                    .__box{
                        display: block;
                        padding: 8%;
                      .__left{
                        width: 100%;
                      }
                      .__right{
                        width: 100%;
                      }
                    }
                  }
                }
        }
            }
            &.__wrap--second {
              padding: $spc-XL 0 0 0;
              text-align: center;
            }
            @include screen-mq(md) {
              &.__wrap--first {
                margin: 0 -20px;
                  .__inner--second {
                      &::before {
                        bottom: 20px;
                      }
                  }
              }
              &.__wrap--second {
                padding: $spc-L 0 0 0;
                text-align: center;
                  .__btn {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin: $spc-L auto 0 auto;
                  }
              }
            }
        }
    }
}
.o-section-02{
  margin-top: 200px;
  @include screen-mq(md) {
  margin-top: 240px;
  }
          .__wrap {
            margin: 64px;
            &.__wrap--first {
              .__head{
                position: relative;
                color: #fff;
                margin-bottom: 160px;
              }
                .__inner {
                  margin-bottom: 240px;
                  .o-container{
                    .__box{
                      p.__subhead{
                        font-size: 12px;
                        color: #c49400;
                        line-height: 2.4em;
                        margin-bottom: 64px;
                      }
                      h2{
                        color: #1173b7;
                        margin-bottom: 8px;
                      }
                    }
                  }
                  &:nth-child(even){
                  .o-container{
                    .__box{
                      position: relative;
                      .__left{
                            position: relative;
                            z-index: 99;
                            width: 60%;
                            top: 0;
                      }
                      .__right{
                        background: #fff;
                        position: absolute;
                        z-index: 9;
                        top: -130px;
                        right: 0;
                        width: 60%;
                        padding: 5% 5% 6% 25%;
                      }
                    }
                  }
                    
                  }
                  &:nth-child(odd){
                  .o-container{
                    .__box{
                      position: relative;
                      .__left{
                        position: absolute;
                        z-index: 99;
                        width: 60%;
                        top: 0;
                        right: 0;
                      }
                      .__right{
                        background: #fff;
                        position: relative;
                        z-index: 9;
                        top: -130px;
                        left: 0;
                        width: 60%;
                        padding: 5% 25% 6% 5%;
                      }
                    }
                  }
                    
                  }
                }
            }
            &.__wrap--second {
              padding: $spc-XL 0 0 0;
              text-align: center;
            }
            @include screen-mq(md) {
              &.__wrap--first {
                margin: 0 -20px;
                padding: 0 8%;
                  .__inner--second {
                      &::before {
                        bottom: 20px;
                      }
                  }
              }
              &.__wrap--second {
                padding: $spc-L 0 0 0;
                text-align: center;
                  .__btn {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin: $spc-L auto 0 auto;
                  }
              }
            }
        }
  @include screen-mq(md) {
          .__wrap {
            margin: 64px;
            &.__wrap--first {
              .__head{
                position: relative;
                color: #fff;
                margin-bottom: 40px;
              }
                .__inner {
                  margin-bottom: 64px;
                  .o-container{
                    .__box{
                      p.__subhead{
                        font-size: 12px;
                        color: #c49400;
                        line-height: 2.4em;
                        margin-bottom: 32px;
                      }
                      h2{
                        color: #1173b7;
                        margin-bottom: 8px;
                      }
                    }
                  }
                  &:nth-child(even){
                  .o-container{
                    .__box{
                      position: relative;
                      .__left{
                            position: relative;
                            z-index: 99;
                            width: 100%;
                            top: 0;
                      }
                      .__right{
                        background: #fff;
                        position: relative;
                        z-index: 9;
                        top: 0;
                        right: 0;
                        width: 100%;
                        padding: 5%;
                      }
                    }
                  }
                    
                  }
                  &:nth-child(odd){
                  .o-container{
                    .__box{
                      position: relative;
                      .__left{
                        position: relative;
                        z-index: 99;
                        width: 100%;
                        top: 0;
                        right: 0;
                      }
                      .__right{
                        background: #fff;
                        position: relative;
                        z-index: 9;
                        top: 0;
                        left: 0;
                        width: 100%;
                        padding: 5%;
                      }
                    }
                  }
                    
                  }
                }
            }
            &.__wrap--second {
              padding: $spc-XL 0 0 0;
              text-align: center;
            }
            @include screen-mq(md) {
              &.__wrap--first {
                margin: 0 -20px;
                padding: 0 8%;
                  .__inner--second {
                      &::before {
                        bottom: 20px;
                      }
                  }
              }
              &.__wrap--second {
                padding: $spc-L 0 0 0;
                text-align: center;
                  .__btn {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin: $spc-L auto 0 auto;
                  }
              }
            }
        }
  }
}


.o-section-03{
          background: $color-base-4;
          padding: $spc-L 0;
          h1.__head{
            color: #fff;
          }
          .__wrap {
            margin: 64px;
            &.__wrap--first {
              .__head{
                margin-bottom: 160px;
              }
                .__inner {
                  .__box{
                        background: #fff;
                        padding: $spc-L;
                    
                    .__table {
                        margin: 0 0 $spc-R 0;
                        width: 100%;
                      tbody tr{
                        border-bottom: 1px solid #dedede;
                      }
                      tbody tr th {
                        width: 36%;
                    }
                      tbody tr th,tbody tr td{
                        padding: 30px;
                      }
                    }
                  }
                }
            }
            &.__wrap--second {
              padding: $spc-XL 0 0 0;
              text-align: center;
            }
            @include screen-mq(md) {
              &.__wrap--first {
                margin: 2%;
                .__inner {
                  .__box{
                        background: #fff;
                        padding: $spc-S;
                    
                    .__table {
                        margin: 0 0 $spc-R 0;
                        width: 100%;
                        text-align: center;
                      tbody tr{
                        border-bottom: 1px solid #dedede;
                      }
                      tbody tr th,tbody tr td{
                        display: block;
                        width: 100%;
                      }
                      tbody tr th {
                        padding: 30px 0 16px 0;
                    }
                      tbody tr td {
                        padding: 0 0 30px;
                    }
                    }
                  }
                }
                  .__inner--second {
                      &::before {
                        bottom: 20px;
                      }
                  }
              }
              &.__wrap--second {
                padding: $spc-L 0 0 0;
                text-align: center;
                  .__btn {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin: $spc-L auto 0 auto;
                  }
              }
            }
        }
}

.__grade_deco01{
height:1200px;
width: 1200px;
background-image: url(../img/site/100_index/img-grade_deco01.png);
position:absolute;
}


.o-section-04{
          padding: $spc-L 0;
          background: #fff;
          h1.__head{
            span{
              color: #999;
            }
          }
          .__wrap {
            margin: 64px;
            .__inner{
              .o-container{
                .__box{
                  .contact_input{
                    .terms{
                      overflow-x: hidden;
                      height: 200px;
                      padding: 32px;
                      border: 1px solid #eee;
                      h2{
                        font-size: 16px;
                      }
                      h3{
                        font-size: 12px;
                      }
                      p{
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
        }
  @include screen-mq(md) {
          .__wrap {
            margin: 2%;
            .__inner{
              .o-container{
                .__box{
                  .contact_input{
                    .terms{
                      overflow-x: hidden;
                      height: 200px;
                      padding: 20px;
                      border: 1px solid #eee;
                      margin-top: 20px;
                      h2{
                        font-size: 16px;
                      }
                      h3{
                        font-size: 12px;
                      }
                      p{
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
        }
  }
}

.__grade_deco01{
height:1200px;
width: 1200px;
background-image: url(../img/site/100_index/img-grade_deco01.png);
position:absolute;
}



/* ---------------------------
  お問い合わせ
--------------------------- */
.contact_group {
	width: 90%;
	margin: 0 auto 40px;
  @include screen-mq(md) {
    width: 100%;
  }
  table {
	width: 100%;
    th,td {
	    font-size: 14px;
      padding: 12px 0;
      @include screen-mq(md) {
        display: block;
      }
    }
    th {
      width: 30%;
      @include screen-mq(md) {
        width: 100%;
        padding: 20px 0 4px;
      }
    }
    td {
	    width: 70%;
      @include screen-mq(md) {
        width: 100%;
        padding: 0;
      }
    }
    input,textarea,select {
      border: 1px solid #eee;
      width: 100%;
      padding-left: 16px;
    }
    textarea {
      height: 180px;
    }
    input[type="text"], input[type="tel"], input[type="email"], select, textarea {
  display: block;
  border: none;
  background: #f4f4f4;
  padding: 10px 16px;
  border-radius: none;
  -webkit-appearance: none;
}

input[type="submit"], input[type="button"] {
  border-radius: 0;
  -webkit-appearance: none;
  border: none;
}

input[type="button"] {
  display: inline-block;
  padding: 18px 50px;
  border: 1px solid #707070;
  background: #FFF;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="submit"] {
  background-position: center;
  background-color: #4d94ae;
  color: #fff;
  font-weight: bold;
  padding: 20px 80px;
  display: block;
  margin: 0 auto;
}

input[type="submit"]:hover {
  opacity: 0.8;
}

input[type="radio"].radio_design {
  display: none;
}
  }
  .submit {
    background-color: #e5004f;
    color: #fff;
    font-weight: 700;
    border: 1px solid #e5004f;
    width: 200px;
    padding: 10px;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
    border-radius: 4px;
    text-align: center;
}
}

.contact_input {
input[type="submit"] {
	background-color: #32b16c;
	color: #fff;
	font-weight: bold;
	border: 2px solid #32b16c;
	width: 200px;
	height: 54px;
	margin: 0 auto;
	display: block;
	margin-top: 10px;
	border-radius: 4px;
}
}
.btnBack {
	color: #999;
	background: #fff;
	font-weight: bold;
	border: 2px solid #999;
	width: 200px;
  padding: 10px;
	margin: 0 auto;
	display: block;
	margin-top: 20px;
	border-radius: 4px;
}
.o-required {
	color: #e4007f;
}


/* ---------------------------
  確認画面
--------------------------- */
.o-page-confirm{
  .o-section-01{
    margin-top: 100px;
    .o-sect{
      .l-container{
        .__wrap{
          .__inner{
            h2.__head{
              text-align: center;
            }
            .__txt{
              text-align: center;
            }
          }
        }
      }
    }
  }
  .contact_group {
	width: 90%;
	margin: 0 auto 40px;
  @include screen-mq(md) {
    width: 100%;
  }
  table {
	width: 100%;
  border-collapse: collapse;
    th,td {
	    font-size: 14px;
      padding: 12px 0;
      border-bottom: 1px solid #ccc;
      @include screen-mq(md) {
        display: block;
      }
    }
    th {
      width: 30%;
      text-align: right;
      padding-right: 24px;
      @include screen-mq(md) {
        width: 100%;
        padding: 20px 0 4px;
        border-bottom: none;
        text-align: center;
      }
    }
    td {
	    width: 70%;
      @include screen-mq(md) {
        width: 100%;
        padding:0 0 20px;
        text-align: center;
      }
    }
    input,textarea,select {
      border: 1px solid #eee;
      width: 100%;
      padding-left: 16px;
    }
    textarea {
      height: 180px;
    }
    input[type="text"], input[type="tel"], input[type="email"], select, textarea {
  display: block;
  border: none;
  background: #f4f4f4;
  padding: 10px 16px;
  border-radius: none;
  -webkit-appearance: none;
}

input[type="submit"], input[type="button"] {
  border-radius: 0;
  -webkit-appearance: none;
  border: none;
}

input[type="button"] {
  display: inline-block;
  padding: 18px 50px;
  border: 1px solid #707070;
  background: #FFF;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="submit"] {
  background-position: center;
  background-color: #4d94ae;
  color: #fff;
  font-weight: bold;
  padding: 20px 80px;
  display: block;
  margin: 0 auto;
}

input[type="submit"]:hover {
  opacity: 0.8;
}

input[type="radio"].radio_design {
  display: none;
}
  }
}
}




/*アニメーション*/
.motion-txt {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.motion-txt:after {
  content: '';
  position: absolute;
  opacity: 1;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #313131;
  transform: translate3d(-101%, 0, 0);
}
.js-scroll.show .motion-txt:after {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 0, 0);
}
.js-scroll.done .motion-txt:after {
  transition-property: transform;
  transition-duration: 0.5s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(103%, 0, 0);
}
.motion-txt .motion-inner {
  display: inline-block;
  opacity: 0;
}
.js-scroll.done .motion-txt .motion-inner {
  opacity: 1;
}
