@charset "UTF-8";

.o-tabel-form {
  width: 100%;
    tbody {
        tr {
            th,td {
              
            }
            th {
              text-align: left;
              font-weight: normal;
              width: 180px;
              padding: $spc-XS;
              vertical-align: middle;
                &.o-table-th-top {
                  padding: $spc-S $spc-XS $spc-XS $spc-XS;
                  vertical-align: top;
                }
            }
            td {
              padding: $spc-XS;
              width: auto;
            }
        }
        @include screen-mq(md) {
          tr {
              th,td {
                display: block;
                width: 100%;
              }
              
          }
        }
		@include screen-mq(xs) {	
		.__reply {
			display: flex;
   			flex-wrap: wrap;
			label {
				margin: 1rem 3.2rem 0 0;
			}
		}
    }
}
}