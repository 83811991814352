@charset "UTF-8";

/* ---------------------------
	easing function
--------------------------- */
/* -- config -------------- */
$ts-property: all;
$ts-duration: 0.5s;
$ts-delay: 0s;

/* -- easing -------------- */
$es-linear:      $ts-property $ts-duration linear $ts-delay;
$es-ease:        $ts-property $ts-duration ease $ts-delay;
$es-in:          $ts-property $ts-duration ease-in $ts-delay;
$es-out:         $ts-property $ts-duration ease-out $ts-delay;
$es-inout:       $ts-property $ts-duration ease-in-out $ts-delay;
$es-in-sine:     $ts-property $ts-duration cubic-bezier(0.47, 0, 0.745, 0.715) $ts-delay;
$es-out-sine:    $ts-property $ts-duration cubic-bezier(0.39, 0.575, 0.565, 1) $ts-delay;
$es-inout-sine:  $ts-property $ts-duration cubic-bezier(0.445, 0.05, 0.55, 0.95) $ts-delay;
$es-in-quad:     $ts-property $ts-duration cubic-bezier(0.55, 0.085, 0.68, 0.53) $ts-delay;
$es-out-quad:    $ts-property $ts-duration cubic-bezier(0.25, 0.46, 0.45, 0.94) $ts-delay;
$es-inout-quad:  $ts-property $ts-duration cubic-bezier(0.455, 0.03, 0.515, 0.955) $ts-delay;
$es-in-cubic:    $ts-property $ts-duration cubic-bezier(0.55, 0.055, 0.675, 0.19) $ts-delay;
$es-out-cubic:   $ts-property $ts-duration cubic-bezier(0.215, 0.61, 0.355, 1) $ts-delay;
$es-inout-cubic: $ts-property $ts-duration cubic-bezier(0.645, 0.045, 0.355, 1) $ts-delay;
$es-in-quart:    $ts-property $ts-duration cubic-bezier(0.895, 0.03, 0.685, 0.22) $ts-delay;
$es-out-quart:   $ts-property $ts-duration cubic-bezier(0.165, 0.84, 0.44, 1) $ts-delay;
$es-inout-quart: $ts-property $ts-duration cubic-bezier(0.77, 0, 0.175, 1) $ts-delay;
$es-in-quint:    $ts-property $ts-duration cubic-bezier(0.755, 0.05, 0.855, 0.06) $ts-delay;
$es-out-quint:   $ts-property $ts-duration cubic-bezier(0.23, 1, 0.32, 1) $ts-delay;
$es-inout-quint: $ts-property $ts-duration cubic-bezier(0.86, 0, 0.07, 1) $ts-delay;
$es-in-expo:     $ts-property $ts-duration cubic-bezier(0.95, 0.05, 0.795, 0.035) $ts-delay;
$es-out-expo:    $ts-property $ts-duration cubic-bezier(0.19, 1, 0.22, 1) $ts-delay;
$es-inout-expo:  $ts-property $ts-duration cubic-bezier(1, 0, 0, 1) $ts-delay;
$es-in-circ:     $ts-property $ts-duration cubic-bezier(0.6, 0.04, 0.98, 0.335) $ts-delay;
$es-out-circ:    $ts-property $ts-duration cubic-bezier(0.075, 0.82, 0.165, 1) $ts-delay;
$es-inout-circ:  $ts-property $ts-duration cubic-bezier(0.785, 0.135, 0.15, 0.86) $ts-delay;
$es-in-back:     $ts-property $ts-duration cubic-bezier(0.6, -0.28, 0.735, 0.045) $ts-delay;
$es-out-back:    $ts-property $ts-duration cubic-bezier(0.175, 0.885, 0.32, 1.275) $ts-delay;
$es-inout-back:  $ts-property $ts-duration cubic-bezier(0.68, -0.55, 0.265, 1.55) $ts-delay;

/* -- keyframes -------------- */
@keyframes animation-hopping {
  0% { transform: translateY(8px); }
  50% { transform: translateY(4px); }
  100% { transform: translateY(8px); }
}