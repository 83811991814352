@charset "UTF-8";

/* ---------------------------
  humberger btn
--------------------------- */
.o-hmbg {
  width: 24px;
  height: 20px;
  display: none;
  flex-direction: column;
  justify-content: center; 
  margin: 0 0 0 auto;
    span {
      width: 24px;
      height: 2px;
      background: $color-black;
      border-radius: 1px;
        &:nth-child(1) {
          transform: translateY(-4px);
        }
        &:nth-child(3) {
          transform: translateY(4px);
        }
    }
}
@include screen-mq(md) {
  .o-hmbg {
    display: flex;
  }
}

/* ---------------------------
  drawer nav
--------------------------- */
.o-overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
}
.o-drawer {
  width: 250px;
  height: 100vh;
  background: $color-base-2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  transition: $dflt-transition;
  transform: translateX(-300px);
    &.__active {
      transform: translateX(0px);
    }
    .__list {
        .__item {
          border-bottom: 1px solid $color-base-4;
            a {
              display: block;
              padding: $spc-S;
            }
        }
    }
    .__inner {
      padding: 0 $spc-S;
      margin: $spc-S 0;
        img {
          vertical-align: bottom;
        }
        .__elem {
          display: inline-block;
          margin: 0 $spc-XS 0 0;
        }
    }
    .o-close {
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 20px;
      right: -40px;
        span {
          background: $color-white;
          width: 30px;
          height: 2px;
          border-radius: 1px;
            &:nth-child(1) {
              transform: translateY(1px) rotate(45deg);
            }
            &:nth-child(2) {
              transform: translateY(-1px) rotate(-45deg);
            }
        }
    }
}

/* ---------------------------
  scroll animation
--------------------------- */
.scroll-animation-obj {
  opacity: 0;
  transition: all 2s ease 0s;
}
.scroll-animation-hop {
  transform: translate(0,120px);
}
.scroll-animation-left {
  transform: translate(-120px,0);
}
.scroll-animation-right {
  transform: translate(120px,0);
}

/* ---------------------------
  fixed cv
--------------------------- */
.o-fixed-cv {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 8;
  transition: $dflt-transition;
  display: none;
    .__bnr {
      vertical-align: bottom;
    }
}
@include screen-mq(xs) {
  .o-fixed-cv {
    display: block;
  }
}